@font-face {
  font-family: 'SF Pro Text';
  font-weight: 600;
  src: local("SF Pro"), url(./fonts/SFProText-Semibold.ttf) format('ttf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 700;
  src: local("SF Pro"),url(./fonts/SFProDisplay-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 400;
  src: local("SF Pro"),url(./fonts/SFProText-Regular.ttf) format('ttf');
}

body {
  margin: 0;
  font-family: SF Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.375rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


